<template>
  <div style="height: 100%; width: 100%">
    <div
      class="haibao"
      @click="tohaibao"
      v-if="shangjia.id == 484 || shangjia.id == 208"
    >
      <img src="../../assets/img/haibao.png" alt="" />
      <!-- <div>生成海报</div> -->
    </div>
    <div style="width: 100%" v-if="shangjia.id == 484 || shangjia.id == 208">
      <div class="xinbbj" ref="bill">
        <img class="newma" src="../../assets/img/yicheyinx.jpg" alt />

        <div class="newwenzi">
          <div class="dinei">
            <img class="newmabot" src="../../assets/img/yicheyinx2.png" alt />
            <div class="newmasd">
              <div class="cname" style="color: #fff">
                -- {{ shangjia.name }} --
              </div>
              <div class="xinma">
                <vue-qr
                  :text="url"
                  :margin="6"
                  colorDark="#000"
                  colorLight="#fff"
                  :dotScale="1"
                  :logoSrc="downloadData.icon"
                  :logoScale="0.2"
                  :size="120"
                ></vue-qr>
              </div>
              <div class="saoname">扫一扫“二维码”</div>
            </div>
          </div>
          <!-- <img class="newma" src="../../assets/img/yicheyinx2.png" alt /> -->
          <div class="wendingwei">
            <div class="namewen">
              门店地址：东莞市黄江镇金钱岭三街7号101室宏开汽修厂
            </div>
            <div class="namewen">联系电话：{{ userInfo.phone }}</div>
          </div>
        </div>
        <!-- <div class="hessnew">
          <div class="mas">
            <div class="cname" style="color: #000;">-- {{shangjia.name}} --</div>
            <div class="xinma">
              <vue-qr
                :text="url"
                :margin="0"
                colorDark="#000"
                colorLight="#fff"
                :dotScale="1"
                :logoSrc="downloadData.icon"
                :logoScale="0.2"
                :size="120"
              ></vue-qr>
            </div>
          </div>
          
        </div>-->
      </div>
      <div class="bott"></div>
    </div>

    <div class v-else>
      <div class="bbj" v-if="list.partnerLevel == 0">
        <img class="img2" src="../../assets/img/tu2.png" alt />

        <div
          class="hess"
          style="position: relative; width: 250px; height: 270px"
        >
          <div class="cname">-- {{ shangjia.name }} --</div>
          <img
            class="img3"
            v-if="shangjia.id == 484 || shangjia.id == 208"
            style="height: 250px; width: 250px"
            src="../../assets/img/hongkai.png"
            alt
          />
          <img
            class="img3"
            v-else
            style="height: 250px; width: 250px"
            src="../../assets/img/tu3.png"
            alt
          />
          <div
            class="ma"
            style="margin-left: -80px; margin-top: 75px; left: 50%"
          >
            <div class="qrcode" ref="qrCodeUrl"></div>
          </div>
        </div>
        <img class="img4" src="../../assets/img/tu4.png" alt />
      </div>
      <div
        :class="shangjia.id == 484 || shangjia.id == 208 ? 'bbjsd' : 'bbjs'"
        v-else
      >
        <div class="img2"></div>
        <div class="hess2" style="position: relative">
          <div class="ma2">
            <vue-qr
              :text="url"
              :margin="0"
              colorDark="#000"
              colorLight="#fff"
              :dotScale="1"
              :logoSrc="downloadData.icon"
              :logoScale="0.2"
              :size="130"
            ></vue-qr>
          </div>
        </div>
        <div class="fenxx">
          <img class="fenimg" src="../../assets/img/fenxiang.png" alt />
          <span style="vertical-align: middle; margin-left: 5px"
            >分享给好友(APP用)</span
          >
        </div>
      </div>
    </div>
    <van-popup class="hbcls" v-model="isDom">
      <img class="imggss" :src="canvasImageUrl" alt />
      <div class="can">长按保存图片</div>
    </van-popup>

    <!-- <div class="bott"></div> -->
    <tabbar :active="2"></tabbar>
  </div>
</template>

<script>
import tabbar from "../../components/tabbar.vue";
import html2canvas from "html2canvas";
import userModel from "../../api/user";
import orderModel from "../../api/order";
import QRCode from "qrcodejs2";
import vueQr from "vue-qr";
export default {
  components: {
    tabbar,
    vueQr,
  },

  data() {
    return {
      list: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      gid: this.$route.query.gid,
      garageName: this.$route.query.garageName,
      customerId: this.$route.query.customerId,
      canvasImageUrl: "",
      isDom: false,
      url: "",
      nameList: {},
      downloadData: {},
    };
  },
  methods: {
    handleErrorFn(e) {
      console.log("fail", e.detail);
    },

    handleLaunchFn(e) {
      console.log("success");
    },
    // toto() {
    //   this.$router.push({
    //     path: 'codema',
    //     query: {
    //       gid: this.shangjia.id,
    //       garageName:this.shangjia.name,
    //       customerId:this.userInfo.id,
    //       name:this.userInfo.userName
    //     }
    //   });
    // },
    onLoadname() {
      const data = {
        gid: this.shangjia.id,
        uid: this.userInfo.id,
      };
      orderModel
        .count(data)
        .then((e) => {
          // loading.clear();
          this.list = e.data;
          this.onLoad();
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      var _this = this;
      userModel
        .tuokema({ customerId: this.customerId, gid: this.gid })
        .then((res) => {
          this.url = res.data.url;
          setTimeout(() => {
            // 调用html转化canvas函数
            _this.htmlToCanvas();
          }, 500);
          if (this.shangjia.id != 484) {
            this.creatQrCode(res.data.url);
          }
        });
    },
    tohaibao() {
      this.isDom = true;
    },
    //生成海报
    htmlToCanvas() {
      html2canvas(this.$refs.bill, {
        useCORS: true, //允许跨域
      }).then((canvas) => {
        let imageUrl = canvas.toDataURL("image/png"); // 将canvas转成base64图片格式
        this.canvasImageUrl = imageUrl;
      });
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: this.$route.query.gid,
        })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["onMenuShareAppMessage"],
              openTagList: ["wx-open-launch-weapp"], // 微信开放标签 小程序跳转按钮：
            });

            var utf = this.shangjia.name;
            var garageName = encodeURIComponent(utf);
            var names = encodeURIComponent(this.userInfo.realName);
            wx.ready(() => {
              //  +"&garageName=" + this.shangjia.name
              wx.onMenuShareAppMessage({
                title: this.userInfo.realName + " 的专属VIP二维码",
                desc: "",
                link:
                  "https://shopping.car-posthouse.cn/codema?gid=" +
                  this.shangjia.id +
                  "&customerId=" +
                  this.userInfo.id +
                  "&name=" +
                  names +
                  "&garageName=" +
                  garageName,
                imgUrl: "",
                success: function () {},
              });
              //          wx.onMenuShareAppMessage({
              //   title: this.userInfo.realName + " 的专属VIP二维码",
              //   desc: "",
              //   link:
              //     "https://shopping.car-posthouse.cn/exclusive?gid=" +
              //     this.shangjia.id +
              //     "&customerId=" +
              //     this.userInfo.id +
              //     "&denglu=" +
              //     true +
              //     "&garageName=" +
              //     garageName,
              //   imgUrl: "",
              //   success: function() {}
              // });
              //         wx.onMenuShareAppMessage({
              //   title: this.userInfo.realName + " 的专属VIP二维码",
              //   desc: "",
              //   link:
              //     "https://shopping.car-posthouse.cn/exclusive/codema?gid=" +
              //     this.shangjia.id +
              //     "&customerId=" +
              //     this.userInfo.id +
              //     "&name=" +
              //     this.userInfo.realName +
              //     "&garageName=" +
              //     garageName,
              //   imgUrl: "",
              //   success: function() {}
              // });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    creatQrCode(url) {
      // var utf = this.nameList.garageName

      //    var garageNameutf = encodeURIComponent(utf);
      // var url =
      //   "https://shopping.car-posthouse.cn/tuoke_initiation?gid=" +
      //   this.gid +
      //   "&garageName=" +
      //   this.garageName +
      //   "&customerId=" +
      //   this.customerId;

      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 160,
        height: 160,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&garageName=" +
        this.$route.query.garageName +
        "&customerId=" +
        this.$route.query.customerId;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then((e) => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
    }
    // console.log(this.$route.params);
    // this.$nextTick(() => {

    // });
    this.onLoadname();
    this.getConfig();
    // var isok = localStorage.getItem("isok");
    // if (isok == "true") {
    //   setTimeout(res => {
    //     // this.getConfig();
    //     this.$router.go(0);
    //   }, 100);
    //   localStorage.removeItem("isok");
    // }
  },
  mounted() {
    console.log(document.body.clientWidth);
    // this.creatQrCode();
  },
};
</script>

<style lang="less" scoped>
.newma {
  width: 100%;
  display: block;
}
.newmasd {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  // padding: 2px 0 6px 0;
  // background: #fff;
  margin-top: 32px;
  // margin: auto;

  border-radius: 10px;
}

.ma {
  position: absolute;
  top: 0;
}
.ma2 {
  position: absolute;
  top: 0;
  margin-left: -60px;
  left: 50%;
  // z-index: 99;
}
.img2 {
  // height: 45px;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: 150px;
}

.cname {
  font-size: 12px;
  color: red;
  text-align: center;
  font-weight: bold;
}
.saoname {
  font-size: 12px;
  color: #000;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}
.img4 {
  height: 45px;
  display: block;
  margin: auto;
  margin-top: 25px;
}
.hess {
  margin: auto;
  margin-top: 25px;
  background: #fff;
}
.hessnew {
  //  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: 60px;
}
.hbcls {
  width: 70%;
  border-radius: 10px;
}
.can {
  font-size: 18px;
  text-align: center;
  line-height: 35px;
}
.xinma {
  text-align: center;
  margin-top: 8px;
}
.mas {
  width: 160px;
  padding: 2px 0 6px 0;
  background: #fff;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}
.hess2 {
  margin: auto;
  margin-top: 360px;
  height: 150px;
  width: 250px;
}
.xinma img {
  width: 100px !important;
  height: 100px !important;
}
.namewen {
  font-size: 13px;
  padding-left: 10px;
  line-height: 20px;
}
.img3 {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  margin: auto;
  height: 250px;
  width: 250px;
}
.xinbbj {
  overflow: hidden;
  // height: calc(100vh - 50px);
  height: 100%;
  width: 100%;
  position: relative;
  // background: url(../../assets/img/saoma2.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background-position: center top;
  // background-attachment: fixed;
  // margin-bottom: 60px;
}
.newwenzi {
  // position: relative;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.newmabot {
  width: 80%;
  margin: auto;
  display: block;
}
.wendingwei {
  // position: absolute;
  // top: 0;
  // left: 0;
  // margin-top: 2%;
  color: #fff;
}
.bott {
  height: 50px;
}
.bbj {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  // background: url(../../assets/img/tu1.png);
  // background: url(../../assets/img/zhuan2.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
  // margin-bottom: 60px;
}
.bbjs {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  background: url(../../assets/img/zhuan2.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
  // margin-bottom: 60px;
}
.bbjsd {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  background: url(../../assets/img/zhuan3.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
}
.imghe {
  display: block;
  margin: auto;
  height: 160px;
  width: 160px;
}
.fenxx {
  color: #fff;
  font-size: 14px;
  margin-top: 65px;
  text-align: center;
}
.fenimg {
  width: 24px;
  vertical-align: middle;
}
.bejs {
  // position: absolute;
  // left: 0;
  width: 100%;
}
.ma2 img {
  width: 120px !important;
  height: 120px !important;
}
.imggss {
  width: 100%;
}
.haibao img {
  width: 46px;
  // height: 46px;
  display: block;
  background: #fff;
  border-radius: 23px;
  padding: 2px;
}
.haibao {
  padding-top: 6px;
  position: fixed;
  bottom: 0;
  margin-bottom: 80px;
  left: 0;
  line-height: 16px;
  z-index: 99;
  margin-left: 12px;
  text-align: center;
}
</style>
